
const getCountryList = (locale) => { 
	const normalizedLocale = locale.toLowerCase();
	let countryList;

	switch (normalizedLocale) {
	  case "en":
		countryList = countryListEN;
		break;
	  case "es":
		countryList = countryListES;
		break;
	  case "cat":
		countryList = countryListCAT;
		break;
	  default:
		console.warn("Locale not recognized, defaulting to English");
		countryList = countryListEN;
	}

	// Sort the country list by the label string alphabetically
	return countryList.sort((a, b) => a.label.localeCompare(b.label));
}

const countryListCAT = [
    { value: "AF", label: "Afganistan" },
    { value: "AL", label: "Albània" },
    { value: "DZ", label: "Algèria" },
    { value: "AS", label: "Samoa Americana" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguila" },
    { value: "AQ", label: "Antàrtida" },
    { value: "AG", label: "Antigua i Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armènia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Austràlia" },
    { value: "AT", label: "Àustria" },
    { value: "AZ", label: "Azerbaidjan" },
    { value: "BS", label: "Bahames" },
    { value: "BH", label: "Baréin" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Bielorússia" },
    { value: "BE", label: "Bèlgica" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benín" },
    { value: "BM", label: "Bermudes" },
    { value: "BT", label: "Butan" },
    { value: "BO", label: "Bolívia" },
    { value: "BQ", label: "Bonaire, Sint Eustatius i Saba" },
    { value: "BA", label: "Bòsnia i Hercegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Illes Bouvet" },
    { value: "BR", label: "Brasil" },
    { value: "IO", label: "Territori Britànic de l'Oceà Índic" },
    { value: "BN", label: "Brunéi Darussalam" },
    { value: "BG", label: "Bulgària" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cap Verd" },
    { value: "KH", label: "Cambodja" },
    { value: "CM", label: "Camerun" },
    { value: "CA", label: "Canadà" },
    { value: "KY", label: "Illes Caiman" },
    { value: "CF", label: "República Centroafricana" },
    { value: "TD", label: "Txad" },
    { value: "CL", label: "Xile" },
    { value: "CN", label: "Xina" },
    { value: "CX", label: "Illa de Nadal" },
    { value: "CC", label: "Illes Cocos (Keeling)" },
    { value: "CO", label: "Colòmbia" },
    { value: "KM", label: "Comores" },
    { value: "CD", label: "Congo (República Democràtica del)" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Illes Cook" },
    { value: "CR", label: "Costa Rica" },
    { value: "HR", label: "Croàcia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Xipre" },
    { value: "CZ", label: "República Txeca" },
    { value: "CI", label: "Costa d'Ivori" },
    { value: "DK", label: "Dinamarca" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "República Dominicana" },
    { value: "EC", label: "Equador" },
    { value: "EG", label: "Egipte" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Guinea Equatorial" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estònia" },
    { value: "SZ", label: "Esuatini" },
    { value: "ET", label: "Etiòpia" },
    { value: "FK", label: "Illes Malvines" },
    { value: "FO", label: "Illes Fèroe" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finlàndia" },
    { value: "FR", label: "França" },
    { value: "GF", label: "Guaiana Francesa" },
    { value: "PF", label: "Polinèsia Francesa" },
    { value: "TF", label: "Territoris Australs Francesos" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gàmbia" },
    { value: "GE", label: "Geòrgia" },
    { value: "DE", label: "Alemanya" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Grècia" },
    { value: "GL", label: "Groenlàndia" },
    { value: "GD", label: "Granada" },
    { value: "GP", label: "Guadalupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bisàu" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haití" },
    { value: "HM", label: "Illes Heard i McDonald" },
    { value: "VA", label: "Santa Seu" },
    { value: "HN", label: "Hondures" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hongria" },
    { value: "IS", label: "Islàndia" },
    { value: "IN", label: "Índia" },
    { value: "ID", label: "Indonèsia" },
    { value: "IR", label: "Iràn" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Irlanda" },
    { value: "IM", label: "Illa de Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Itàlia" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japó" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordània" },
    { value: "KZ", label: "Kazajistan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Corea del Nord" },
    { value: "KR", label: "Corea del Sud" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kirguizistan" },
    { value: "LA", label: "Laos" },
    { value: "LV", label: "Letònia" },
    { value: "LB", label: "Líban" },
    { value: "LS", label: "Lesoto" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Líbia" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lituània" },
    { value: "LU", label: "Luxemburg" },
    { value: "MO", label: "Macau" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malàisia" },
    { value: "MV", label: "Maldivas" },
    { value: "ML", label: "Malí" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Illes Marshall" },
    { value: "MQ", label: "Martinica" },
    { value: "MR", label: "Mauritània" },
    { value: "MU", label: "Maurici" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mèxic" },
    { value: "FM", label: "Micronèsia" },
    { value: "MD", label: "Moldàvia" },
    { value: "MC", label: "Mònaco" },
    { value: "MN", label: "Mongòlia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Marroc" },
    { value: "MZ", label: "Moçambic" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namíbia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Països Baixos" },
    { value: "NC", label: "Nova Caledònia" },
    { value: "NZ", label: "Nova Zelanda" },
    { value: "NI", label: "Nicaragüa" },
    { value: "NE", label: "Níger" },
    { value: "NG", label: "Nigèria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Illes Norfolk" },
    { value: "MP", label: "Illes Marianes del Nord" },
    { value: "NO", label: "Noruega" },
    { value: "OM", label: "Oman" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestina" },
    { value: "PA", label: "Panamà" },
    { value: "PG", label: "Papua Nova Guinea" },
    { value: "PY", label: "Paraguai" },
    { value: "PE", label: "Perú" },
    { value: "PH", label: "Filipines" },
    { value: "PN", label: "Illes Pitcairn" },
    { value: "PL", label: "Polònia" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "RE", label: "Reunió" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Rússia" },
    { value: "RW", label: "Ruanda" },
    { value: "BL", label: "São Bartolomeu" },
    { value: "SH", label: "Santa Helena" },
    { value: "KN", label: "Saint Kitts i Nevis" },
    { value: "LC", label: "Santa Llúcia" },
    { value: "MF", label: "Saint Martin" },
    { value: "PM", label: "Saint Pierre i Miquelon" },
    { value: "VC", label: "Saint Vincent i les Granadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "São Tomé i Príncipe" },
    { value: "SA", label: "Aràbia Saudita" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Sèrbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Serra Leone" },
    { value: "SG", label: "Singapur" },
    { value: "SX", label: "Sint Maarten" },
    { value: "SK", label: "Eslovàquia" },
    { value: "SI", label: "Eslovènia" },
    { value: "SB", label: "Illes Salomó" },
    { value: "SO", label: "Somàlia" },
    { value: "ZA", label: "Sud-àfrica" },
    { value: "GS", label: "Geòrgia del Sud i les Illes Sandwich del Sud" },
    { value: "SS", label: "Sudan del Sud" },
    { value: "ES", label: "Espanya" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan" },
    { value: "SR", label: "Surinam" },
    { value: "SJ", label: "Svalbard i Jan Mayen" },
    { value: "SE", label: "Suècia" },
    { value: "CH", label: "Suïssa" },
    { value: "SY", label: "Síria" },
    { value: "TW", label: "Taiwan" },
    { value: "TJ", label: "Tadjikistan" },
    { value: "TZ", label: "Tanzània" },
    { value: "TH", label: "Tailàndia" },
    { value: "TL", label: "Timor Oriental" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinitat i Tobago" },
    { value: "TN", label: "Tunísia" },
    { value: "TR", label: "Turquia" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Illes Turks i Caicos" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ucraïna" },
    { value: "AE", label: "Emirat Àrab Unit" },
    { value: "GB", label: "Regne Unit" },
    { value: "US", label: "Estats Units" },
    { value: "UY", label: "Uruguai" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venèzuela" },
    { value: "VN", label: "Vietnam" },
    { value: "WF", label: "Wallis i Futuna" },
    { value: "EH", label: "Sàhara Occidental" },
    { value: "YE", label: "Iemen" },
    { value: "ZM", label: "Zàmbia" },
    { value: "ZW", label: "Zimbàbue" }
];


const countryListES = [
    { value: "AF", label: "Afganistán" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Argelia" },
    { value: "AS", label: "Samoa Americana" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguila" },
    { value: "AQ", label: "Antártida" },
    { value: "AG", label: "Antigua y Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaiyán" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Baréin" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Bielorrusia" },
    { value: "BE", label: "Bélgica" },
    { value: "BZ", label: "Belice" },
    { value: "BJ", label: "Benín" },
    { value: "BM", label: "Bermudas" },
    { value: "BT", label: "Bután" },
    { value: "BO", label: "Bolivia" },
    { value: "BQ", label: "Bonaire, Sint Eustatius y Saba" },
    { value: "BA", label: "Bosnia y Herzegovina" },
    { value: "BW", label: "Botsuana" },
    { value: "BV", label: "Isla Bouvet" },
    { value: "BR", label: "Brasil" },
    { value: "IO", label: "Territorio Británico del Océano Índico" },
    { value: "BN", label: "Brunéi Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KH", label: "Camboya" },
    { value: "CM", label: "Camerún" },
    { value: "CA", label: "Canadá" },
    { value: "KY", label: "Islas Caimán" },
    { value: "CF", label: "República Centroafricana" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Isla de Navidad" },
    { value: "CC", label: "Islas Cocos (Keeling)" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoras" },
    { value: "CD", label: "Congo (República Democrática del)" },
    { value: "CG", label: "Congo" },
    { value: "CK", label: "Islas Cook" },
    { value: "CR", label: "Costa Rica" },
    { value: "HR", label: "Croacia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Chipre" },
    { value: "CZ", label: "República Checa" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "DK", label: "Dinamarca" },
    { value: "DJ", label: "Yibuti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "República Dominicana" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egipto" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Guinea Ecuatorial" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "SZ", label: "Esuatini" },
    { value: "ET", label: "Etiopía" },
    { value: "FK", label: "Islas Malvinas" },
    { value: "FO", label: "Islas Feroe" },
    { value: "FJ", label: "Fiyi" },
    { value: "FI", label: "Finlandia" },
    { value: "FR", label: "Francia" },
    { value: "GF", label: "Guayana Francesa" },
    { value: "PF", label: "Polinesia Francesa" },
    { value: "TF", label: "Territorios Australes Franceses" },
    { value: "GA", label: "Gabón" },
    { value: "GM", label: "Gambia" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Alemania" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Grecia" },
    { value: "GL", label: "Groenlandia" },
    { value: "GD", label: "Granada" },
    { value: "GP", label: "Guadalupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bisáu" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haití" },
    { value: "HM", label: "Islas Heard y McDonald" },
    { value: "VA", label: "Santa Sede" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungría" },
    { value: "IS", label: "Islandia" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Irán" },
    { value: "IQ", label: "Irak" },
    { value: "IE", label: "Irlanda" },
    { value: "IM", label: "Isla de Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italia" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japón" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordania" },
    { value: "KZ", label: "Kazajistán" },
    { value: "KE", label: "Kenia" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Corea del Norte" },
    { value: "KR", label: "Corea del Sur" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kirguistán" },
    { value: "LA", label: "Laos" },
    { value: "LV", label: "Letonia" },
    { value: "LB", label: "Líbano" },
    { value: "LS", label: "Lesoto" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libia" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lituania" },
    { value: "LU", label: "Luxemburgo" },
    { value: "MO", label: "Macao" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malasia" },
    { value: "MV", label: "Maldivas" },
    { value: "ML", label: "Malí" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Islas Marshall" },
    { value: "MQ", label: "Martinica" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauricio" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "México" },
    { value: "FM", label: "Micronesia" },
    { value: "MD", label: "Moldavia" },
    { value: "MC", label: "Mónaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Marruecos" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Birmania (Myanmar)" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Países Bajos" },
    { value: "NC", label: "Nueva Caledonia" },
    { value: "NZ", label: "Nueva Zelanda" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Níger" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Isla Norfolk" },
    { value: "MP", label: "Islas Marianas del Norte" },
    { value: "NO", label: "Noruega" },
    { value: "OM", label: "Omán" },
    { value: "PK", label: "Pakistán" },
    { value: "PW", label: "Palaos" },
    { value: "PS", label: "Palestina" },
    { value: "PA", label: "Panamá" },
    { value: "PG", label: "Papúa Nueva Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Perú" },
    { value: "PH", label: "Filipinas" },
    { value: "PN", label: "Islas Pitcairn" },
    { value: "PL", label: "Polonia" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Catar" },
    { value: "RE", label: "Reunión" },
    { value: "RO", label: "Rumanía" },
    { value: "RU", label: "Rusia" },
    { value: "RW", label: "Ruanda" },
    { value: "BL", label: "San Bartolomé" },
    { value: "SH", label: "Santa Helena" },
    { value: "KN", label: "San Cristóbal y Nieves" },
    { value: "LC", label: "Santa Lucía" },
    { value: "MF", label: "San Martín" },
    { value: "PM", label: "San Pedro y Miquelón" },
    { value: "VC", label: "San Vicente y las Granadinas" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Santo Tomé y Príncipe" },
    { value: "SA", label: "Arabia Saudita" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leona" },
    { value: "SG", label: "Singapur" },
    { value: "SX", label: "Sint Maarten" },
    { value: "SK", label: "Eslovaquia" },
    { value: "SI", label: "Eslovenia" },
    { value: "SB", label: "Islas Salomón" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "Sudáfrica" },
    { value: "GS", label: "Georgia del Sur e Islas Sandwich del Sur" },
    { value: "SS", label: "Sudán del Sur" },
    { value: "ES", label: "España" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudán" },
    { value: "SR", label: "Surinam" },
    { value: "SJ", label: "Svalbard y Jan Mayen" },
    { value: "SE", label: "Suecia" },
    { value: "CH", label: "Suiza" },
    { value: "SY", label: "Siria" },
    { value: "TW", label: "Taiwán" },
    { value: "TJ", label: "Tayikistán" },
    { value: "TZ", label: "Tanzania" },
    { value: "TH", label: "Tailandia" },
    { value: "TL", label: "Timor Oriental" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad y Tobago" },
    { value: "TN", label: "Túnez" },
    { value: "TR", label: "Turquía" },
    { value: "TM", label: "Turkmenistán" },
    { value: "TC", label: "Islas Turcas y Caicos" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ucrania" },
    { value: "AE", label: "Emiratos Árabes Unidos" },
    { value: "GB", label: "Reino Unido" },
    { value: "US", label: "Estados Unidos" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistán" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela" },
    { value: "VN", label: "Vietnam" },
    { value: "VG", label: "Islas Vírgenes Británicas" },
    { value: "VI", label: "Islas Vírgenes de los EE. UU." },
    { value: "WF", label: "Wallis y Futuna" },
    { value: "EH", label: "Sáhara Occidental" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabue" }
];
  
const countryListEN = [
    { value: "AF", label: "Afghanistan" },
    { value: "AL", label: "Albania" },
    { value: "DZ", label: "Algeria" },
    { value: "AS", label: "American Samoa" },
    { value: "AD", label: "Andorra" },
    { value: "AO", label: "Angola" },
    { value: "AI", label: "Anguilla" },
    { value: "AQ", label: "Antarctica" },
    { value: "AG", label: "Antigua and Barbuda" },
    { value: "AR", label: "Argentina" },
    { value: "AM", label: "Armenia" },
    { value: "AW", label: "Aruba" },
    { value: "AU", label: "Australia" },
    { value: "AT", label: "Austria" },
    { value: "AZ", label: "Azerbaijan" },
    { value: "BS", label: "Bahamas (the)" },
    { value: "BH", label: "Bahrain" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbados" },
    { value: "BY", label: "Belarus" },
    { value: "BE", label: "Belgium" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Benin" },
    { value: "BM", label: "Bermuda" },
    { value: "BT", label: "Bhutan" },
    { value: "BO", label: "Bolivia (Plurinational State of)" },
    { value: "BQ", label: "Bonaire, Sint Eustatius and Saba" },
    { value: "BA", label: "Bosnia and Herzegovina" },
    { value: "BW", label: "Botswana" },
    { value: "BV", label: "Bouvet Island" },
    { value: "BR", label: "Brazil" },
    { value: "IO", label: "British Indian Ocean Territory (the)" },
    { value: "BN", label: "Brunei Darussalam" },
    { value: "BG", label: "Bulgaria" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "CV", label: "Cabo Verde" },
    { value: "KH", label: "Cambodia" },
    { value: "CM", label: "Cameroon" },
    { value: "CA", label: "Canada" },
    { value: "KY", label: "Cayman Islands (the)" },
    { value: "CF", label: "Central African Republic (the)" },
    { value: "TD", label: "Chad" },
    { value: "CL", label: "Chile" },
    { value: "CN", label: "China" },
    { value: "CX", label: "Christmas Island" },
    { value: "CC", label: "Cocos (Keeling) Islands (the)" },
    { value: "CO", label: "Colombia" },
    { value: "KM", label: "Comoros (the)" },
    { value: "CD", label: "Congo (the Democratic Republic of the)" },
    { value: "CG", label: "Congo (the)" },
    { value: "CK", label: "Cook Islands (the)" },
    { value: "CR", label: "Costa Rica" },
    { value: "HR", label: "Croatia" },
    { value: "CU", label: "Cuba" },
    { value: "CW", label: "Curaçao" },
    { value: "CY", label: "Cyprus" },
    { value: "CZ", label: "Czechia" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "DK", label: "Denmark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominica" },
    { value: "DO", label: "Dominican Republic (the)" },
    { value: "EC", label: "Ecuador" },
    { value: "EG", label: "Egypt" },
    { value: "SV", label: "El Salvador" },
    { value: "GQ", label: "Equatorial Guinea" },
    { value: "ER", label: "Eritrea" },
    { value: "EE", label: "Estonia" },
    { value: "SZ", label: "Eswatini" },
    { value: "ET", label: "Ethiopia" },
    { value: "FK", label: "Falkland Islands (the) [Malvinas]" },
    { value: "FO", label: "Faroe Islands (the)" },
    { value: "FJ", label: "Fiji" },
    { value: "FI", label: "Finland" },
    { value: "FR", label: "France" },
    { value: "GF", label: "French Guiana" },
    { value: "PF", label: "French Polynesia" },
    { value: "TF", label: "French Southern Territories (the)" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambia (the)" },
    { value: "GE", label: "Georgia" },
    { value: "DE", label: "Germany" },
    { value: "GH", label: "Ghana" },
    { value: "GI", label: "Gibraltar" },
    { value: "GR", label: "Greece" },
    { value: "GL", label: "Greenland" },
    { value: "GD", label: "Grenada" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GU", label: "Guam" },
    { value: "GT", label: "Guatemala" },
    { value: "GG", label: "Guernsey" },
    { value: "GN", label: "Guinea" },
    { value: "GW", label: "Guinea-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "HT", label: "Haiti" },
    { value: "HM", label: "Heard Island and McDonald Islands" },
    { value: "VA", label: "Holy See (the)" },
    { value: "HN", label: "Honduras" },
    { value: "HK", label: "Hong Kong" },
    { value: "HU", label: "Hungary" },
    { value: "IS", label: "Iceland" },
    { value: "IN", label: "India" },
    { value: "ID", label: "Indonesia" },
    { value: "IR", label: "Iran (Islamic Republic of)" },
    { value: "IQ", label: "Iraq" },
    { value: "IE", label: "Ireland" },
    { value: "IM", label: "Isle of Man" },
    { value: "IL", label: "Israel" },
    { value: "IT", label: "Italy" },
    { value: "JM", label: "Jamaica" },
    { value: "JP", label: "Japan" },
    { value: "JE", label: "Jersey" },
    { value: "JO", label: "Jordan" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "KI", label: "Kiribati" },
    { value: "KP", label: "Korea (the Democratic People's Republic of)" },
    { value: "KR", label: "Korea (the Republic of)" },
    { value: "KW", label: "Kuwait" },
    { value: "KG", label: "Kyrgyzstan" },
    { value: "LA", label: "Lao People's Democratic Republic (the)" },
    { value: "LV", label: "Latvia" },
    { value: "LB", label: "Lebanon" },
    { value: "LS", label: "Lesotho" },
    { value: "LR", label: "Liberia" },
    { value: "LY", label: "Libya" },
    { value: "LI", label: "Liechtenstein" },
    { value: "LT", label: "Lithuania" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MG", label: "Madagascar" },
    { value: "MW", label: "Malawi" },
    { value: "MY", label: "Malaysia" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malta" },
    { value: "MH", label: "Marshall Islands (the)" },
    { value: "MQ", label: "Martinique" },
    { value: "MR", label: "Mauritania" },
    { value: "MU", label: "Mauritius" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexico" },
    { value: "FM", label: "Micronesia (Federated States of)" },
    { value: "MD", label: "Moldova (the Republic of)" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolia" },
    { value: "ME", label: "Montenegro" },
    { value: "MS", label: "Montserrat" },
    { value: "MA", label: "Morocco" },
    { value: "MZ", label: "Mozambique" },
    { value: "MM", label: "Myanmar" },
    { value: "NA", label: "Namibia" },
    { value: "NR", label: "Nauru" },
    { value: "NP", label: "Nepal" },
    { value: "NL", label: "Netherlands (the)" },
    { value: "NC", label: "New Caledonia" },
    { value: "NZ", label: "New Zealand" },
    { value: "NI", label: "Nicaragua" },
    { value: "NE", label: "Niger (the)" },
    { value: "NG", label: "Nigeria" },
    { value: "NU", label: "Niue" },
    { value: "NF", label: "Norfolk Island" },
    { value: "MP", label: "Northern Mariana Islands (the)" },
    { value: "NO", label: "Norway" },
    { value: "OM", label: "Oman" },
    { value: "PK", label: "Pakistan" },
    { value: "PW", label: "Palau" },
    { value: "PS", label: "Palestine, State of" },
    { value: "PA", label: "Panama" },
    { value: "PG", label: "Papua New Guinea" },
    { value: "PY", label: "Paraguay" },
    { value: "PE", label: "Peru" },
    { value: "PH", label: "Philippines (the)" },
    { value: "PN", label: "Pitcairn" },
    { value: "PL", label: "Poland" },
    { value: "PT", label: "Portugal" },
    { value: "PR", label: "Puerto Rico" },
    { value: "QA", label: "Qatar" },
    { value: "MK", label: "Republic of North Macedonia" },
    { value: "RO", label: "Romania" },
    { value: "RU", label: "Russian Federation (the)" },
    { value: "RW", label: "Rwanda" },
    { value: "RE", label: "Réunion" },
    { value: "BL", label: "Saint Barthélemy" },
    { value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha" },
    { value: "KN", label: "Saint Kitts and Nevis" },
    { value: "LC", label: "Saint Lucia" },
    { value: "MF", label: "Saint Martin (French part)" },
    { value: "PM", label: "Saint Pierre and Miquelon" },
    { value: "VC", label: "Saint Vincent and the Grenadines" },
    { value: "WS", label: "Samoa" },
    { value: "SM", label: "San Marino" },
    { value: "ST", label: "Sao Tome and Principe" },
    { value: "SA", label: "Saudi Arabia" },
    { value: "SN", label: "Senegal" },
    { value: "RS", label: "Serbia" },
    { value: "SC", label: "Seychelles" },
    { value: "SL", label: "Sierra Leone" },
    { value: "SG", label: "Singapore" },
    { value: "SX", label: "Sint Maarten (Dutch part)" },
    { value: "SK", label: "Slovakia" },
    { value: "SI", label: "Slovenia" },
    { value: "SB", label: "Solomon Islands" },
    { value: "SO", label: "Somalia" },
    { value: "ZA", label: "South Africa" },
    { value: "GS", label: "South Georgia and the South Sandwich Islands" },
    { value: "SS", label: "South Sudan" },
    { value: "ES", label: "Spain" },
    { value: "LK", label: "Sri Lanka" },
    { value: "SD", label: "Sudan (the)" },
    { value: "SR", label: "Suriname" },
    { value: "SJ", label: "Svalbard and Jan Mayen" },
    { value: "SE", label: "Sweden" },
    { value: "CH", label: "Switzerland" },
    { value: "SY", label: "Syrian Arab Republic (the)" },
    { value: "TW", label: "Taiwan (Province of China)" },
    { value: "TJ", label: "Tajikistan" },
    { value: "TZ", label: "Tanzania, United Republic of" },
    { value: "TH", label: "Thailand" },
    { value: "TL", label: "Timor-Leste" },
    { value: "TG", label: "Togo" },
    { value: "TK", label: "Tokelau" },
    { value: "TO", label: "Tonga" },
    { value: "TT", label: "Trinidad and Tobago" },
    { value: "TN", label: "Tunisia" },
    { value: "TR", label: "Turkey" },
    { value: "TM", label: "Turkmenistan" },
    { value: "TC", label: "Turks and Caicos Islands (the)" },
    { value: "TV", label: "Tuvalu" },
    { value: "UG", label: "Uganda" },
    { value: "UA", label: "Ukraine" },
    { value: "AE", label: "United Arab Emirates (the)" },
    { value: "GB", label: "United Kingdom (the)" },
    { value: "UM", label: "United States Minor Outlying Islands (the)" },
    { value: "US", label: "United States of America (the)" },
    { value: "UY", label: "Uruguay" },
    { value: "UZ", label: "Uzbekistan" },
    { value: "VU", label: "Vanuatu" },
    { value: "VE", label: "Venezuela (Bolivarian Republic of)" },
    { value: "VN", label: "Viet Nam" },
    { value: "VG", label: "Virgin Islands (British)" },
    { value: "VI", label: "Virgin Islands (U.S.)" },
    { value: "WF", label: "Wallis and Futuna" },
    { value: "EH", label: "Western Sahara" },
    { value: "YE", label: "Yemen" },
    { value: "ZM", label: "Zambia" },
    { value: "ZW", label: "Zimbabwe" },
    { value: "AX", label: "Åland Islands" }
];


const countryList = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Israel",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan (the)",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (the)",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

const countryAndCodeList = {
    "AF": "Afghanistan",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas (the)",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory (the)",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands (the)",
    "CF": "Central African Republic (the)",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands (the)",
    "CO": "Colombia",
    "KM": "Comoros (the)",
    "CD": "Congo (the Democratic Republic of the)",
    "CG": "Congo (the)",
    "CK": "Cook Islands (the)",
    "CR": "Costa Rica",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "CI": "Côte d'Ivoire",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic (the)",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "SZ": "Eswatini",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (the) [Malvinas]",
    "FO": "Faroe Islands (the)",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories (the)",
    "GA": "Gabon",
    "GM": "Gambia (the)",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See (the)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (the Democratic People's Republic of)",
    "KR": "Korea (the Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic (the)",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands (the)",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (the Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands (the)",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger (the)",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands (the)",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines (the)",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "MK": "Republic of North Macedonia",
    "RO": "Romania",
    "RU": "Russian Federation (the)",
    "RW": "Rwanda",
    "RE": "Réunion",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan (the)",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands (the)",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates (the)",
    "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
    "UM": "United States Minor Outlying Islands (the)",
    "US": "United States of America (the)",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Åland Islands"
};

const getCountryCodeFromName = (countryName) => {
	return Object.keys(countryAndCodeList).find(code => countryAndCodeList[code] === countryName);
}


const getCountryNameFromCode = (countryCode, locale) => {
	const normalizedLocale = locale.toLowerCase();
  
	let countryList;
  
	switch (normalizedLocale) {
	  case "en":
		countryList = countryListEN;
		break;
	  case "es":
		countryList = countryListES;
		break;
	  case "cat":
		countryList = countryListCAT;
		break;
	  default:
		countryList = countryListEN;
	}
  
	const country = countryList.find(item => item.value.toUpperCase() === countryCode.toUpperCase());
  
	return country ? country.label : null;
}

export { countryList, countryAndCodeList, getCountryCodeFromName, getCountryNameFromCode, getCountryList };